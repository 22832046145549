<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <h3 class="mb-2">Восстановление пароля</h3>
      <p>Если вы забыли пароль, введите E-Mail. Контрольная строка для смены пароля, а также ваши регистрационные данные, будут высланы вам по E-Mail.</p>
      <VForm v-model="valid" lazy-validation  ref="form" @submit.prevent="onSubmit">
        <VRow>
          <VCol cols="12">
            <div :class="$style.label">E-mail</div>
            <VTextField v-model="email" outlined dense hide-details="auto" :rules="rules.required" />
          </VCol>
          <VCol cols="12">
            <div :class="$style.label">Введите слово на картинке</div>
            <div class="mb-2">
              <VImg :src="img" width="180" height="40" />
            </div>
            <VTextField v-model="captcha" outlined dense hide-details="auto" :rules="rules.required" />
          </VCol>
          <VCol cols="12">
            <VBtn color="primary" width="100%" large type="submit" depressed :loading="pending">Восстановить</VBtn>
          </VCol>
          <VCol>
            <router-link :class="$style.control" to="/">
              Вернуться к авторизации
            </router-link>
          </VCol>
        </VRow>
      </VForm>
    </div>
  </div>
</template>

<script>
import { split, last } from 'lodash-es';
import api from '@/store/user/api';

import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'UserLoginView',
  data() {
    return {
      img: '',
      valid: true,
      email: '',
      captcha: '',
      rules: {
        required: [(v) => !!v || 'Обязательное поле'],
      },
    };
  },
  computed: {
    ...mapGetters({
      pending: 'user/pending',
    }),
    sid() {
      return last(split(this.img, 'captcha_sid='));
    }
  },
  methods: {
    ...mapActions({
      forgotUser: 'user/forgotUser',
    }),
    getCaptcha: function() {
      return api.forgot({}).then(({ captchaImg }) => {
        this.img = captchaImg;
      });
    },
    onSubmit: function() {
      if (this.$refs.form.validate()) {
        const email = this.email;
        const captcha = this.captcha;
        const sid = this.sid;
        this.forgotUser({
          email,
          captcha,
          sid,
        }).catch(() => {
          this.getCaptcha();
        });
      }
    }
  },
  mounted() {
    this.getCaptcha();
  },
}
</script>

<style module lang="scss">
.root {
  max-width: 400px;
  margin: auto;
}
.inner {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  font-size: 14px;
}
.label {
  font-weight: 500;
  margin-bottom: 8px;
}
.control {
  text-align: center;
  font-size: 12px;
}
.link {
  color: #f57f29;
  text-decoration: underline;
}
</style>
